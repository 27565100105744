import * as classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../utils/data-hooks'
import * as sc from '../classes.scss'
import * as s from './title.scss'
import {TitleProps} from '.'

export const Title = ({title, listLayout, listStripWidth, mobile, visible, cardsLayout, cardMargins}: TitleProps) => {
  if (!visible) {
    return null
  }
  const fromTitleBaselineToCards = `calc(72px - .3em - ${cardMargins}px)`

  const padding = {
    mobileLayout:{
      padding: '20px 30px'
    },
    cardsLayout:{
      padding: `calc(72px - .3em) 30px ${fromTitleBaselineToCards}`,
    },
    listLayout:{
      padding: `55px 30px ${50 + listStripWidth}px`
    },
    defaultLayout:{
      padding: `calc(72px - .3em) 30px calc(52px - .3em)`
    }
  }

  return (
    <h2
      className={classNames(s.title, sc.textLineHeight, {[s.mobile]: mobile})}
      style={
        mobile ? padding.mobileLayout
        : cardsLayout ? padding.cardsLayout 
          : listLayout ? padding.listLayout 
            : padding.defaultLayout
      }
      data-hook={DH.listTitle}
    >       
        {title}
        
    </h2>
  )
}
